import { OTP_VERIFY_ACTION } from "../action/VerifyOtpAction"


const InitialState={
    otpVerification:"",
}

const VerifyOtpReducer=(state=InitialState,action)=>{
    switch(action.type){
        case OTP_VERIFY_ACTION:{
            return{
                ...state,
                otpVerification: action.payload,
            }
        }
       
        default:{
            return{
                ...state,
            };
        }
    }
}

export default VerifyOtpReducer