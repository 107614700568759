import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './App.scss';
import App from './App';
import dotenv from 'dotenv';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { slk } from "survey-core";
import { surveyJsKey } from "./config/base";

dotenv.config();

slk.licenseKey = surveyJsKey;

const Root = () => {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);