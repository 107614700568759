import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../assets/images/logo.png";
import Dummy from "../assets/images/icons/ellipse.svg";
import { LogoutModal } from "./modals/LogoutModal";
import { Link, useNavigate } from "react-router-dom";

import Draft from "../assets/images/icons/draft.svg";
import Groups from "../assets/images/icons/Iconly-Bold-2-User.svg";
import Logout from "../assets/images/icons/logout.svg";
import Account from "../assets/images/icons/my-account.svg";
import Notification from "../assets/images/icons/notification.svg";
import Schedule from "../assets/images/icons/Iconly-Bold-Calendar.svg";
import Send from "../assets/images/icons/Send2.svg";

export default function Sidebar() {
  let navigate = useNavigate();
  const [logout, setLogout] = useState(false);
  const [path, setPatgh] = useState("Dashboard");
  const showLogout = () => setLogout(true);

  // useEffect(()=>{
  //   console.log("call this");
  // },[location])

  const handleClose = () => {
    setLogout(false);
  };
  return (
    <>
      <Navbar key="xl" expand="xl" className="leftSideBar flex-column">
        <Navbar.Brand href="#" className="mx-0">
          <img src={Logo} alt="Mailtrail" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-xl`}
          aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
              <Navbar.Brand href="#" className="mx-0">
                <img src={Logo} alt="Mailtrail" />
              </Navbar.Brand>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="flex-column align-content-bewteen h-100">
            <Nav className="flex-grow-1 pe-3 flex-column">
              <button
                type="button"
                onClick={() => {
                  navigate("/create-new-campaign")
                  setPatgh("NewCampaign")
                }}
                style={{
                  marginLeft: "19px",
                  padding: "9px",
                  borderRadius: "5px",
                  backgroundColor: "#954E97",
                  border: "none",
                  color: "#ffffff",
                }}
              >
                + &nbsp;&nbsp;&nbsp; New Campaign
              </button>
              <Link
                className={`nav-link ${
                  path === "Dashboard" ? "btn-outline-inverse rounded-pill" : ""
                }`}
                onClick={() => setPatgh("Dashboard")}
                eventKey="1"
                to="/Dashboard"
              >
                <img src={Send} alt="Sent" /> Sent
              </Link>
              <Link
                className={`nav-link ${
                  path === "drafts" ? "btn-outline-inverse rounded-pill" : ""
                }`}
                onClick={() => setPatgh("drafts")}
                eventKey="2"
                to="/drafts"
              >
                <img src={Draft} alt="Drafts" /> Drafts
              </Link>
              <Link
                className={`nav-link ${
                  path === "Scheduled" ? "btn-outline-inverse rounded-pill" : ""
                }`}
                onClick={() => setPatgh("Scheduled")}
                eventKey="3"
                to="/Scheduled"
              >
                <img src={Schedule} alt="Scheduled" /> Scheduled
              </Link>
              <Link
                className={`nav-link ${
                  path === "groups" ? "btn-outline-inverse rounded-pill" : ""
                }`}
                onClick={() => setPatgh("groups")}
                eventKey="4"
                to="/groups"
              >
                <img src={Groups} alt="Groups" /> Groups
              </Link>
              <div>
                <Link
                  className={`nav-link ${
                    path === "notifications"
                      ? "btn-outline-inverse rounded-pill"
                      : ""
                  }`}
                  onClick={() => setPatgh("notifications")}
                  eventKey="5"
                  to="/notifications"
                >
                  <img src={Notification} alt="Notifications" /> Notifications
                </Link>
                <Link
                  className={`nav-link ${
                    path === "my-account"
                      ? "btn-outline-inverse rounded-pill"
                      : ""
                  }`}
                  onClick={() => setPatgh("my-account")}
                  eventKey="6"
                  to="/my-account"
                >
                  <img src={Account} alt="Account" /> My Account
                </Link>
              </div>
              <a
                className="nav-link"
                style={{cursor:"pointer"}}
                onClick={() => {
                  showLogout();
                }}
              >
                <img src={Logout} alt="Notifications" /> Logout
              </a>
            </Nav>
            <span className="copyRight">
              <img src={Dummy} alt="icon" /> 2023, Mailtrail
            </span>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
      <LogoutModal
        show={logout}
        handleClose={handleClose}
        size="custom-sm-350"
      />
    </>
  );
}
