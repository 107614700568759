// import { createStore, combineReducers, applyMiddleware } from "redux";

// import thunk from "redux-thunk";
// import { composeWithDevTools } from 'redux-devtools-extension'
// import { loginReducer } from "./redux/reducer/LoginReducer";

// const rootReducer = combineReducers({
//     login:loginReducer,
// })

// const intialstate = {}

// const middleware = [thunk]

// const store = createStore(rootReducer, intialstate, composeWithDevTools(applyMiddleware(...middleware)))

// export default store;


import { createStore, combineReducers,applyMiddleware } from "redux";
import ReduxThunk from 'redux-thunk';
import AttributeUser from "./redux/reducer/LoginReducer";
import VerifyEmailReducer from "./redux/reducer/VerifyReducer";
import UpdatePasswordReducer from "./redux/reducer/UpdatePasswordReducer";
import VerifyOtpReducer from "./redux/reducer/VerifyOtpReducer";
import AvatarReducer from "./redux/reducer/AvatarReducer";

const rootReducer=combineReducers({
   login:AttributeUser,
   verify:VerifyEmailReducer,
   updatePass:UpdatePasswordReducer,
   otp:VerifyOtpReducer,
   modalava:AvatarReducer
})

const store= createStore(rootReducer,applyMiddleware(ReduxThunk))

export default store

