// import {GET_LOGIN_REQUEST,GET_LOGIN_SUCCESS,GET_LOGIN_FAIL} from '../constants/userConstant'

// export const loginReducer=(state={login:[]},action)=>{
//     switch(action.type){
//         case GET_LOGIN_REQUEST:
//             return{loading:true,login:[]}
        
//         case GET_LOGIN_SUCCESS:
//             return{loading:false,login:action.payload}
            
//         case GET_LOGIN_FAIL:
//             return{loading:false,error:action.payload}

//         default:
//             return state;    
//     }
// }


import { LOGIN_ACTION } from "../action/LoginAction"

const InitialState={
    loginAction:[],
}

const AttributeUser=(state=InitialState,action)=>{
    switch(action.type){
        case LOGIN_ACTION:{
            return{
                ...state,
                loginAction: action.payload,
            }
        }
       
        default:{
            return{
                ...state,
            };
        }
    }
}

export default AttributeUser






// import axios from "axios";


// export const LOGIN_ACTION = "LOGIN_ACTION"

// export const AddAttributeAction = (userObj) => {
//     return async (dispatch) => {
//         axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
//         await axios.post(`https://quytech.net/mailtrail/api/v1/login`,userObj)
//             .then((res) => {
//                 console.log(res, "res")
//                 dispatch({ type: LOGIN_ACTION, payload: res.data});
//             }).catch(error => {
//                 console.log(error);
//             })
//     }
// }
// import { LOGIN_ACTION } from "../action/LoginAction"

// const InitialState={
//     loginAction:[],
// }

// const AttributeUser=(state=InitialState,action)=>{
//     switch(action.type){
//         case LOGIN_ACTION:{
//             return{
//                 ...state,
//                 loginAction: action.payload,
//             }
//         }
       
//         default:{
//             return{
//                 ...state,
//             };
//         }
//     }
// }

// export default AttributeUser
// import { createStore, combineReducers,applyMiddleware } from "redux";
// import ReduxThunk from 'redux-thunk';
// import AttributeUser from "./redux/reducer/LoginReducer";

// const rootReducer=combineReducers({
//    login:AttributeUser,
// })

// const store= createStore(rootReducer,applyMiddleware(ReduxThunk))

// export default store

