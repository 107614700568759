import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthOutlet } from './components/outlets/AuthOutlet';
import { InnerOutlet } from './components/outlets/InnerOutlet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkUserToken } from './APIComponents/User';
import { ConfigCatProvider, createConsoleLogger, LogLevel } from "configcat-react";
import { configCatSdkKey } from './config/base';

const Drafts = React.lazy(() => import('./pages/All/Drafts'));
const Schedule = React.lazy(() => import('./pages/All/Scheduled'));
const MyAccount = React.lazy(() => import('./pages/All/MyAccount'));
const ScheduleCampaign = React.lazy(() => import('./pages/All/ScheduleCampaign'));
const DraftCampaign = React.lazy(() => import('./pages/All/DraftCampaign'));
const CreateQuizList = React.lazy(() => import('./pages/All/CreateQuizList'));
const UpdatePassword = React.lazy(() => import('./pages/authentication/UpdatePassword'));
const Login = React.lazy(() => import('./pages/authentication/Login'));
const VerificationCode = React.lazy(() => import('./pages/authentication/VerificationCode'));
const OTP = React.lazy(() => import('./pages/authentication/OTP'));
const Dashboard = React.lazy(() => import('./pages/All/Dashboard'));
const Notifications = React.lazy(() => import('./pages/All/Notifications'));
const CreateNewCampaign = React.lazy(() => import('./pages/All/CreateNewCampaign'));
const Groups = React.lazy(() => import('./pages/All/Groups'));
const AddNewGroup = React.lazy(() => import('./pages/All/AddNewGroup'));
const UpdateGroup = React.lazy(() => import('./pages/All/UpdateGroup'));
const QuestionList = React.lazy(() => import('./pages/All/QuestionList'));
const CreateQuiz = React.lazy(() => import('./pages/All/CreateQuiz'));
const EvaluationPage = React.lazy(() => import('./pages/All/ConductEvaluation'));
const LearningModulePage = React.lazy(() => import('./pages/All/LearningModulePage'));
const OpenAttachmentPage = React.lazy(() => import('./pages/All/OpenAttachmentPage'));
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const logger = createConsoleLogger(LogLevel.Info);

  const [headerChange, setHeaderChange] = useState();

  useEffect(() => {
    const { pathname } = location;
    // Exclude the EvaluationPage route from the authentication check
    if (
      pathname !== "/" &&
      pathname !== "/verification" &&
      pathname !== "/otp" &&
      pathname !== "/update-password" &&
      !pathname.startsWith("/learning_modules/")
    ) {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
      }
      let getUserId = localStorage.getItem("userId");
      checkAuth(getUserId);
    }
  }, [location]);

  const checkAuth = (id) => {
    const token = localStorage.getItem("authToken");
    checkUserToken(id).then((response) => {
      console.log("response", response.data);
      if (response.data && response.data.auth_link_token) {
        if (response.data.auth_link_token === token) {
          console.log("same****");
        } else {
          navigate("/");
          console.log("Not same*****");
        }
      }
    }).catch((error) => {
      navigate("/");
      console.log("error----", error);
    });
  };

  const sendChangeFlag = () => {
    setHeaderChange(Math.floor(Math.random() * 10000));
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ConfigCatProvider sdkKey={configCatSdkKey} options={{ logger }}>
        <Routes>
          {/* Public Routes */}
          <Route path='/learning_modules/:moduleId/evaluation/:evaluationToken' element={<EvaluationPage />} />
          <Route path='/learning_modules/:moduleId/:moduleToken' element={<LearningModulePage />} />
          <Route path='/learning_modules/:moduleId/attachments/:attachmentToken' element={<OpenAttachmentPage />} />

          {/* Auth Routes */}
          <Route path='/' element={<AuthOutlet />}>
            <Route index element={<Login />} />
            <Route path='/verification' element={<VerificationCode />} />
            <Route path='/otp' element={<OTP />} />
            <Route path='/update-password' element={<UpdatePassword />} />
          </Route>

          {/* Protected Routes */}
          <Route element={<InnerOutlet headerChange={headerChange} />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/notifications' element={<Notifications />} />
            <Route path='/create-new-campaign' element={<CreateNewCampaign />} />
            <Route path='/groups' element={<Groups />} />
            <Route path='/add-new-group' element={<AddNewGroup />} />
            <Route path='/update-group/:id' element={<UpdateGroup />} />
            <Route path='/question-list' element={<QuestionList />} />
            <Route path='/create-quiz' element={<CreateQuiz />} />
            <Route path='/drafts' element={<Drafts />} />
            <Route path='/scheduled' element={<Schedule />} />
            <Route path='/my-account' element={<MyAccount sendChangeFlag={sendChangeFlag} />} />
            <Route path='/schedule-campaign/:id' element={<ScheduleCampaign />} />
            <Route path='/draft-campaign/:id' element={<DraftCampaign />} />
            <Route path='/create-quiz-list' element={<CreateQuizList />} />
          </Route>
        </Routes>

        <ToastContainer />
      </ConfigCatProvider>
    </Suspense>
  );
}

export default App;