import React from 'react'
import { Outlet } from 'react-router-dom'

const BGImage = require('../../assets/images/auth-banner.png'); 

export const AuthOutlet = () => {
  return (
    <div className='bGCoverImage d-grid justify-content-center align-items-center' style={{backgroundImage:`url(${BGImage})`}}>
        <Outlet/>
    </div>
  )
}
