import axios from "axios";
import { baseUrl } from "../../config/base";
import { getAuth } from "../../APIComponents/auth";

export const AVATAR_ACTION = "AVATAR_ACTION";

export const AvatarAction = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${baseUrl}/api/v1/change-avtar`, data, {
        headers: {
          Authorization: `Bearer ${getAuth()}`,
        },
      })
      .then((res) => {
        if (res && res.data) {
          dispatch({ type: AVATAR_ACTION, payload: res.data });
          return res;
        }
      })
      .catch((err) => {
        throw Error(err);
      });
  };
};
