import React from 'react'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

export const LogoutModal = ({show, size, handleClose}) => {
  const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("is_verified")
  }

  return (
    <Modal show={show} onHide={handleClose} 
    className='modalStyleTwo'
    size={size}
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Body>
        <div className='LogoutWrapper'>
            <p className='modalSmPara mb-0 px-lg-5 text-center'>Are you sure you want to logout?</p>
            
            <div className='d-flex flex-column align-items-center col-7 gap-3 justify-content-between'>
                <div className='d-flex gap-3 w-100'>
                    <Link to="/" className='btn btn-secondary w-100 rounded-pill btn-h-43' onClick={logout} >Yes</Link>
                </div>
                <Link className='btnMdLink' onClick={()=>handleClose()}>No</Link>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
