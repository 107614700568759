import axios from "axios";
import {toast} from 'react-toastify'
import { baseUrl } from "../../config/base";

export const EMAIL_VERIFY="EMAIL_VERIFY"
//export const OTP_VERIFY="OTP_VERIFY"

export const VerifyEmailAction = (userObj,navigate) => {
    return async (dispatch) => {
        return axios
          .post(`${baseUrl}/api/v1/resend-otp`, userObj)
          .then((res) => {
            if (res && res.data) {
              dispatch({ type: EMAIL_VERIFY, payload: res.data});
              return res;
            }
          })
          .catch((err) => {
            throw Error(err);
          });
      };
}

// export const VerifyOtpAction = (userObj,navigate) => {
//     return async (dispatch) => {
//         axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
//         await axios.post(`https://quytech.net/mailtrail/api/v1/verify-otp`,userObj)
//             .then((res) => {
//                 console.log("check");
//                 console.log(res, "res")
//                // dispatch({ type: OTP_VERIFY, payload: res});
                
//             }).catch(error => {
//                 console.log(error);
//             })
//     }
// }

// export const VerifyOtpAction = (userObj,navigate) => {
//     return async (dispatch) => {
//         axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
//         await axios.post(`https://quytech.net/mailtrail/api/v1/verify-otp`,userObj)
//             .then((res) => {
//                 console.log(res,"res")
                
//                 // dispatch({ type: LOGIN_ACTION, payload: res.data});
//             }).catch(error => {
//                 console.log(error);
//             })
//     }
// }