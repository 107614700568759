import { EMAIL_VERIFY,OTP_VERIFY } from "../action/VerifyEmail";

const InitialState={
    verifyEmail:"",
}

const VerifyEmailReducer=(state=InitialState,action)=>{
    switch(action.type){
        case EMAIL_VERIFY:{
            return{
                ...state,
                verifyEmail: action.payload,
            }
        }
       
        default:{
            return{
                ...state,
            };
        }
    }
}

export default VerifyEmailReducer