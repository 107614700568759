import axios from "axios";
import { baseUrl } from "../../config/base";


export const UPDATE_PASSWORD = "UPDATE_PASSWORD"

export const UpdatePasswordAction = (userObj,navigate) => {
    return async (dispatch) => {
        return axios
          .post(`${baseUrl}/api/v1/change-password`, userObj)
          .then((res) => {
            if (res && res.data) {
              return res;
            }
          })
          .catch((err) => {
            throw Error(err);
          });
      };
}