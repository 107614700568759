import React, { useEffect, useState } from "react";

export default function Header({ headerChange }) {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [theme, setTheme] = useState("");
  useEffect(() => {
    setManagerProfile()
  }, []);
  
  useEffect(() => {
    console.log("headerChange---",headerChange);
    setManagerProfile()
  }, [headerChange]);

  const setManagerProfile = () => {
    let usertheme = localStorage.getItem("theme");
    let userimage = localStorage.getItem("imageId");
    console.log("userimage",userimage);
    if (userimage) {
      setImage(userimage);
    }
    if (usertheme) {
      if (usertheme === "lightPurple") {
        setTheme("#AF5997");
      } else {
        setTheme(usertheme);
      }
    }
    let userName = localStorage.getItem("name");
    setName(userName)
  }
  return (
    <div className="headerWrapper d-flex justify-content-end align-items-center sticky-top">
      <div className="headerTitle">
        {name}
        <span style={{opacity:"1",padding:"5px",backgroundColor:`${theme?theme:"#AF5997"}`}}>
          {/* <img src={ImageConstant.Frog} alt="" /> */}
          {theme ? 
                            <img src={`../../assets/images/${image}.png`} alt="" />
                            :
                            <img src={"../../assets/images/alligator.png"} alt="" />}
        </span>
      </div>
    </div>
  );
}
