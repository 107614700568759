import axios from "axios";
import { baseUrl } from "../config/base";
import { getAuth } from "./auth";

export const checkUserToken = async (id) => {
  return axios
  .post(`${baseUrl}/api/v1/verify-token?user_id=${id}`,{},{
    headers: {
      Authorization: `Bearer ${getAuth()}`,
    },
  })
  .then((res) => {
    if (res && res.data) {
      return res;
    }
  })
  .catch((err) => {
    throw Error(err);
  });
};
