
import { UPDATE_PASSWORD } from "../action/updatePasswordAction";
const InitialState={
    update:[],
}

const UpdatePasswordReducer=(state=InitialState,action)=>{
    switch(action.type){
        case UPDATE_PASSWORD:{
            return{
                ...state,
                update: action.payload,
            }
        }
       
        default:{
            return{
                ...state,
            };
        }
    }
}

export default UpdatePasswordReducer






// import axios from "axios";

