import axios from "axios";
import { baseUrl } from "../../config/base";

export const OTP_VERIFY_ACTION = "OTP_VERIFY_ACTION";

export const VerifyOtpAction = (userObj, navigate) => {
  return async (dispatch) => {
    return axios
      .post(`${baseUrl}/api/v1/verify-otp`, userObj)
      .then((res) => {
        if (res && res.data) {
          return res;
        }
      })
      .catch((err) => {
        throw Error(err);
      });
  };
};
