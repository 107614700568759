import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../Sidebar'
import Header from '../Header'

export const InnerOutlet = ({headerChange}) => {
  return (
    <div className='InnerMainContentWrapper'>
        <Sidebar/>
        <div className='main-container'>
            <Header headerChange={headerChange}/>
            <div className='OutletHolder'>
                <Outlet/>
            </div>
        </div>
    </div>
  )
}
