import { AVATAR_ACTION } from "../action/AvatarAction";

const InitialState={
    ava:"",
}

const AvatarReducer=(state=InitialState,action)=>{
    switch(action.type){
        case AVATAR_ACTION:{
            return{
                ...state,
                ava: action.payload,
            }
        }
       
        default:{
            return{
                ...state,
            };
        }
    }
}

export default AvatarReducer






// import axios from "axios";
