import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../config/base";

export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGIN_VERIFY = "LOGIN_VERIFY";

export const AddAttributeAction = (userObj) => {
  return async (dispatch) => {
    return axios
      .post(`${baseUrl}/api/v1/login`, userObj)
      .then((res) => {
        if (res && res.data) {
          dispatch({ type: LOGIN_ACTION, payload: res.data });
          return res;
        }
      })
      .catch((err) => {
        throw Error(err);
      });
  };
};

export const storeUserData = (result) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ACTION, payload: result });
  };
};
